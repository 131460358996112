import {useInputControl} from '@conform-to/react'
import { REGEXP_ONLY_DIGITS_AND_CHARS, type OTPInputProps } from 'input-otp'
import React, {useId} from 'react'
import {RadioGroup, type RadioGroupProps} from "#app/components/ui/radio-group.tsx";
import {Select, SelectContent, SelectTrigger, SelectValue} from "#app/components/ui/select.tsx";
import {Switch} from "#app/components/ui/switch.tsx";
import {Checkbox, type CheckboxProps} from './ui/checkbox.tsx'
import {Input} from './ui/input.tsx'
import {Label} from './ui/label.tsx'
import {Textarea} from './ui/textarea.tsx'
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSeparator,
	InputOTPSlot
} from "#app/components/ui/input-otp.tsx";

type Direction = 'ltr' | 'rtl';
export type ListOfErrors = Array<string | null | undefined> | null | undefined

export function ErrorList({
	id,
	errors,
}: {
	errors?: ListOfErrors
	id?: string
}) {
	const errorsToRender = errors?.filter(Boolean)
	if (!errorsToRender?.length) return null
	return (
		<ul id={id} className="flex flex-col gap-1">
			{errorsToRender.map(e => (
				<li key={e} className="text-[10px] text-foreground-destructive">
					{e}
				</li>
			))}
		</ul>
	)
}

export function Field({
	labelProps,
	inputProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	inputProps: React.InputHTMLAttributes<HTMLInputElement>
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<Input
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				{...inputProps}
			/>
				{errorId ? (
					<div className="min-h-[32px] px-4 pb-3 pt-1">
						<ErrorList id={errorId} errors={errors} />
					</div>
				) : null}
		</div>
	)
}

export function OTPField({
													 labelProps,
													 inputProps,
													 errors,
													 className,
												 }: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	inputProps: Partial<OTPInputProps & { render: never }>
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<InputOTP
				pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
				maxLength={6}
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				{...inputProps}
			>
				<InputOTPGroup>
					<InputOTPSlot index={0} />
					<InputOTPSlot index={1} />
					<InputOTPSlot index={2} />
				</InputOTPGroup>
				<InputOTPSeparator />
				<InputOTPGroup>
					<InputOTPSlot index={3} />
					<InputOTPSlot index={4} />
					<InputOTPSlot index={5} />
				</InputOTPGroup>
			</InputOTP>
			<div className="min-h-[32px] px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

export function TextareaField({
	labelProps,
	textareaProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	textareaProps: React.TextareaHTMLAttributes<HTMLTextAreaElement>
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = textareaProps.id ?? textareaProps.name ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<Textarea
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				{...textareaProps}
			/>
			<div className="min-h-[32px] px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

export function CheckboxField({
	labelProps,
	buttonProps,
	errors,
	className,
}: {
	labelProps: JSX.IntrinsicElements['label']
	buttonProps: CheckboxProps & {
		name: string
		form: string
		value?: string
	}
	errors?: ListOfErrors
	className?: string
}) {
	const { key, defaultChecked, ...checkboxProps } = buttonProps
	const fallbackId = useId()
	const checkedValue = buttonProps.value ?? 'on'
	const input = useInputControl({
		key,
		name: buttonProps.name,
		formId: buttonProps.form,
		initialValue: defaultChecked ? checkedValue : undefined,
	})
	const id = buttonProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined

	return (
		<div className={className}>
			<div className="flex gap-2">
				<Checkbox
					{...checkboxProps}
					id={id}
					aria-invalid={errorId ? true : undefined}
					aria-describedby={errorId}
					checked={input.value === checkedValue}
					onCheckedChange={state => {
						input.change(state.valueOf() ? checkedValue : '')
						buttonProps.onCheckedChange?.(state)
					}}
					onFocus={event => {
						input.focus()
						buttonProps.onFocus?.(event)
					}}
					onBlur={event => {
						input.blur()
						buttonProps.onBlur?.(event)
					}}
					type="button"
				/>
				<label
					htmlFor={id}
					{...labelProps}
					className="self-center text-body-xs text-muted-foreground"
				/>
			</div>
			<div className="px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

// implement SwitchField similarily to CheckboxField
export function SwitchField({
	labelProps,
	buttonProps,
	errors,
	className,
}: {
	labelProps: JSX.IntrinsicElements['label']
	buttonProps: CheckboxProps & {
		name: string
		form: string
		value?: string
	}
	errors?: ListOfErrors
	className?: string
}) {
	const { key, defaultChecked, ...checkboxProps } = buttonProps
	const fallbackId = useId()
	const checkedValue = buttonProps.value ?? 'on'
	const input = useInputControl({
		key,
		name: buttonProps.name,
		formId: buttonProps.form,
		initialValue: defaultChecked ? checkedValue : undefined,
	})
	const id = buttonProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined

	return (
		<div className={className}>
			<div className="flex gap-2">
				<Switch
					{...checkboxProps}
					id={id}
					aria-invalid={errorId ? true : undefined}
					aria-describedby={errorId}
					checked={input.value === checkedValue}
					onCheckedChange={state => {
						input.change(state.valueOf() ? checkedValue : '')
						buttonProps.onCheckedChange?.(state)
					}}
					onFocus={event => {
						input.focus()
						buttonProps.onFocus?.(event)
					}}
					onBlur={event => {
						input.blur()
						buttonProps.onBlur?.(event)
					}}
					type="button"
				/>
				<label
					htmlFor={id}
					{...labelProps}
					className="self-center text-body-xs text-muted-foreground"
				/>
			</div>
			<div className="px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)

														}

export function RadioGroupField({
	labelProps,
	buttonProps,
	errors,
	className,
}: {
	labelProps: JSX.IntrinsicElements['label']
	buttonProps: RadioGroupProps & {
		name: string
		form: string
		value: string
	},
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = buttonProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined

	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<RadioGroup
				{...buttonProps}
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
			/>
			<div className="min-h-[32px] px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)

}

export interface SelectFieldProps {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	selectProps: React.SelectHTMLAttributes<HTMLSelectElement>
	errors?: ListOfErrors
	className?: string
	children?: React.ReactNode
}


export const SelectField: React.FC<SelectFieldProps> = ({
																													labelProps,
																													selectProps,
																													errors,
																													className,
																													children,
																												}) => {
	const fallbackId = useId();
	const id = selectProps.id ?? selectProps.name ?? fallbackId;
	const errorId = errors?.length ? `${id}-error` : undefined;

	// Extract onChange from selectProps
	const { onChange, value, defaultValue, dir, ...restSelectProps } = selectProps;

	let stringValue: string | undefined;
	if (Array.isArray(value)) {
		stringValue = value.join(',');
	} else if (typeof value === 'number') {
		stringValue = value.toString();
	} else {
		stringValue = value?.toString();
	}

	let defaultStringValue: string | undefined = undefined;

	if (typeof defaultValue === 'string') {
		defaultStringValue = defaultValue
	}
	if (typeof defaultValue === 'number') {
		defaultStringValue = defaultValue.toString()
	}

	if (Array.isArray(defaultValue)) {
		defaultStringValue = defaultValue.join(', ')
	}

	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<Select
				value={stringValue}
				onValueChange={(state) => {
					console.log('changing state', state);
					onChange?.({ target: { value: state } } as React.ChangeEvent<HTMLSelectElement>); // Simulate onChange event
				}}
				defaultValue={defaultStringValue}
				dir={dir as Direction}
				{...restSelectProps} // Spread the rest of the selectProps
			>
				<SelectTrigger className={className}>
					<SelectValue aria-label={stringValue}>
						{stringValue}
					</SelectValue>
					{/*<SelectIcon />*/}
				</SelectTrigger>
				<SelectContent>
					{children}
				</SelectContent>
			</Select>
			<div className="min-h-[32px] px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	);
};

